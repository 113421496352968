<template lang="">
  <Layout>
    <PageHeader :title="title"></PageHeader>
    <div class="row col-12" id="article">
      <div class="row col-4">
        <div
          class="card m-3"
          style="box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); width: 100%"
        >
          <div class="card-body">
            <div class="d-flex align-items-center">
              <section class="d-flex flex-column flex-grow-1">
                <span
                  class="font-weight-bold"
                  style="font-size: 18px; color: black"
                  >Target</span
                >
                <span class="" style="font-size: 1.25rem">{{
                  dashboerd_count
                }}</span>
              </section>
              <i class="fa fa-user" style="font-size: 30px; color: grey"></i>
            </div>
          </div>
        </div>
      </div>
      <template v-if="dashboerd_data">
        <div
          class="row col-4"
          v-for="(value, key) in dashboerd_data"
          :key="key"
        >
          <div
            class="w-100 card m-3"
            style="box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2)"
          >
            <div class="card-body">
              <div class="d-flex">
                <section class="d-flex flex-column flex-grow-1">
                  <span
                    class="font-weight-bold"
                    style="
                      font-size: 18px;
                      color: black;
                      text-transform: capitalize;
                    "
                    >{{ key }}</span
                  >
                  <span class="" style="font-size: 1.25rem">{{ value }}</span>
                </section>
                <!-- {"sent":0,"failed":0,"delivered":0,"read":0,"queued":0,"initiated":0,"not_sent":0} -->
                <i
                  v-if="key === 'sent'"
                  class="mdi mdi-file-send"
                  style="font-size: 40px; color: green"
                ></i>
                <i
                  v-if="key === 'failed'"
                  class="mdi mdi-cancel"
                  style="font-size: 40px; color: red"
                ></i>
                <i
                  v-if="key === 'delivered'"
                  class="mdi mdi-truck-delivery"
                  style="font-size: 40px; color: green"
                ></i>
                <i
                  v-if="key === 'read'"
                  class="mdi mdi-read"
                  style="font-size: 40px; color: #0073cf"
                ></i>
                <i
                  v-if="key === 'queued'"
                  class="mdi mdi-checkbox-multiple-marked-outline"
                  style="font-size: 40px; color: orange"
                ></i>
                <i
                  v-if="key === 'initiated'"
                  class="mdi mdi-clock-start"
                  style="font-size: 40px; color: #0073cf"
                ></i>
                <i
                  v-if="key === 'engaged'"
                  class="mdi mdi-account-multiple"
                  style="font-size: 40px; color: orange"
                ></i>
                <i
                  v-if="key === 'not_sent'"
                  class="mdi mdi-comment-alert"
                  style="font-size: 40px; color: orange"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </Layout>
</template>
<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import IndoMixin from "../../../mixins/ModuleJs/touchpoint";

export default {
  mixins: [MixinRequest, IndoMixin],
  components: {
    Layout,
    PageHeader,
  },

  data() {
    return {
      title: "Touchpoint",
      //   items: [
      //     {
      //       text: "Back",
      //       href: "/",
      //     },
      //     {
      //       text: "List",
      //     },
      //   ],
    };
  },
};
</script>
